import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLocations } from "../../app/features/locations/API";
import { selectLocations } from "../../app/features/locations/locationsSlice";
import Card from "../../components/Card/Card";
import "./Home.scss";

export default function Home() {
  const dispatch = useDispatch();
  const locations = useSelector(selectLocations);

  useEffect(() => {
    if (locations === null) {
      dispatch(getLocations());
    }
  }, [dispatch, locations]);

  return (
    <div>
      <h2>Select a Location</h2>
      <div className="cards">
        {locations && locations.map((el) => <Card key={el.id} data={el} />)}
      </div>
    </div>
  );
}
