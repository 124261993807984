import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  selectChecked,
  resetChecked,
} from "../../app/features/locations/locationsSlice";
import { deleteCheck } from "../../app/features/locations/API";
import Button from "../../components/Button/Button";
import "./Detail.scss";

export default function Detail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { first_name, last_name, number, checkin_id } =
    useSelector(selectChecked);

  const [popup, setPopup] = useState(false);

  const handlePopup = () => setPopup(!popup);

  const handleConfirm = () => {
    if (popup) {
      dispatch(deleteCheck(checkin_id));
    }
    dispatch(resetChecked());
    navigate("/checkin");
  };

  return (
    <div>
      {!popup && <h2>Welcome {`${first_name} ${last_name}`}</h2>}
      <div className="detail">
        <div className="location-box">
          {popup ? (
            <h6 className="fw-light">
              Are you sure you want to be removed from the list.
            </h6>
          ) : (
            <>
              <h6 className="fw-light">Number in Line</h6>
              <h1 className="mt-5 mb-5 number">{number}</h1>
            </>
          )}

          <div className="button-section">
            <Button name={popup ? "Yes" : "Complete"} onClick={handleConfirm} />
            <Button
              name={popup ? "No" : "Leave"}
              onClick={handlePopup}
              cancel="true"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
