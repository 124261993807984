import React from "react";
import { useNavigate } from "react-router-dom";
import "./Card.scss";

export default function Card({ data }) {
  const navigate = useNavigate();
  const { name, address } = data;

  const handleCheck = () => {
    localStorage.setItem("checked", JSON.stringify(data));
    navigate("/checkin");
  };

  return (
    <div className="card-section">
      <div onClick={handleCheck} className="location-box">
        <p>
          <small>{name}</small>
        </p>
        <h5>{address}</h5>
      </div>
    </div>
  );
}
