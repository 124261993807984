import { createSlice } from "@reduxjs/toolkit";
import { getLocations, checkIn, deleteCheck } from "./API";

const initialState = {
  value: null,
  checked: null,
  status: "idle",
};

export const counterSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    resetChecked: (state) => {
      state.checked = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getLocations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.value = action.payload.locations;
        state.status = "idle";
      })
      .addCase(checkIn.pending, (state) => {
        state.status = "loading";
      })
      .addCase(checkIn.fulfilled, (state, action) => {
        state.checked = action.payload;
        state.status = "idle";
      })
      .addCase(deleteCheck.fulfilled, (state, action) => {
        state.checked = null;
      });
  },
});

export const { resetChecked } = counterSlice.actions;

export const selectLocations = (state) => state.locations.value;
export const selectStatus = (state) => state.locations.status;
export const selectChecked = (state) => state.locations.checked;

export default counterSlice.reducer;
