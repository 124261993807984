import React from "react";
import "./Button.scss";

export default function Button({ name, cancel = false, ...attr }) {
  return (
    <button
      className={`btn ${cancel ? "btn-secondary" : "btn-primary"}`}
      {...attr}
    >
      {name}
    </button>
  );
}
