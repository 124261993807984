import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import logo from "./assets/logo.png";
import Home from "./pages/Home/Home";
import Checkin from "./pages/Checkin/Checkin";
import Detail from "./pages/Detail/Detail";
import "./App.scss";
import { useSelector } from "react-redux";
import { selectStatus } from "./app/features/locations/locationsSlice";

export default function App() {
  const status = useSelector(selectStatus);

  return (
    <div className="App">
      <a href="/">
        <img src={logo} width="55" alt="logo" />
      </a>
      {status === "loading" ? (
        <h2>Loading...</h2>
      ) : (
        <div className="container">
          <BrowserRouter>
            <Routes>
              <Route path="/">
                <Route index element={<Home />} />
                <Route path="checkin" element={<Checkin />} />
                <Route path="detail" element={<Detail />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </div>
      )}
    </div>
  );
}
