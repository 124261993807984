import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  selectChecked,
  resetChecked,
} from "../../app/features/locations/locationsSlice";
import { formatPhoneNumber } from "../../helpers/validate";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { checkIn } from "../../app/features/locations/API";
import "./Checkin.scss";

export default function Checkin() {
  const dispatch = useDispatch();
  const checked = useSelector(selectChecked);
  const { id, address } = JSON.parse(localStorage.getItem("checked"));

  const [phone, setPhone] = useState("");
  const [invalid, setInvalid] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (checked && checked.success === "Success") {
      navigate("/detail");
    }
  }, [checked, navigate]);

  useEffect(() => {
    phone.length === 1 && dispatch(resetChecked());
    setInvalid(phone.length < 14);
  }, [phone, dispatch]);

  const handlePhone = ({ target }) => {
    setPhone(formatPhoneNumber(target.value));
  };

  const handleSubmit = () => {
    dispatch(checkIn({ location_id: id, phone }));
  };

  return (
    <div>
      <h2>Check In</h2>
      <div className="check-in">
        <div className="location-box">
          <h6>Location</h6>

          <h5>{address}</h5>

          <p>Delivery Phone Number</p>
          {checked && checked.success === "Failed" && (
            <p className="failed">{checked.error_message}</p>
          )}

          <div className="mb-5">
            <Input
              name="Phone"
              onChange={handlePhone}
              value={phone}
              valid={invalid}
            />
            <Button
              onClick={handleSubmit}
              type="button"
              disabled={invalid}
              name="Check In"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
