import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const DOMAIN = "dev.apirequests.com";
const PUBLIC_API_KEY =
  "37rDHXhBz4dGQZI4C4kDI9dCBPhcJNxMUZnzTE7qJk3whMBTRWuw2I5EunQa8lX0";

export const getLocations = createAsyncThunk(
  "locations/getLocations",
  async () => {
    const url = `https://${DOMAIN}/api/v2/dispatch/checkin_locations?key=${PUBLIC_API_KEY}`;
    const response = await axios.get(url);
    return response.data;
  }
);

export const checkIn = createAsyncThunk(
  "locations/checkIn",
  async (phoneData) => {
    const url = `https://${DOMAIN}/api/v2/dispatch/checkin?key=${PUBLIC_API_KEY}`;
    const response = await axios.post(url, phoneData);
    return response.data;
  }
);

export const deleteCheck = createAsyncThunk(
  "locations/deleteCheck",
  async (id) => {
    const url = `https://${DOMAIN}/api/v2/dispatch/checkin?key=${PUBLIC_API_KEY}&checkin_id=${id}`;
    const response = await axios.delete(url);
    return response.data;
  }
);
