import React from "react";
import "./Input.scss";

export default function Input({ name, valid = false, value, ...attr }) {
  return (
    <div className="material-textfield">
      <input placeholder=" " name={name} value={value} {...attr} type="text" />
      <label>{name}</label>
      {value && valid && <p>Invalid Phone number</p>}
    </div>
  );
}
